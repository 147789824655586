//import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import React, { useEffect,useState} from 'react';
import PropTypes from 'prop-types';
//import ReactDOM from 'react-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {AgGridColumn, AgGridReact } from 'ag-grid-react';
//import TabPanel from '@mui/lab/TabPanel'
//import TabContext from '@mui/lab/TabContext';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ElectionGrid(props){
  const numberSort = (num1, num2) => {
    var result = (parseInt(num1) - parseInt(num2));
    return result;
  };
  const itemSort = (item1, item2) => {
    var result = (parseInt(item2.VoteTotal) - parseInt(item1.VoteTotal));
    return result;
  };
  const { children, value, index, ...other } = props;
  const [gridApi, setApi] = useState(null);
  const [data, setData] = useState(null);
  const rank = (_data, value) => {
    if(_data)
    {
      var gridData = _data;
      gridData = gridData.sort(itemSort);
      var rank = 1;
      for (var i = 0; i < gridData.length; i++) {
        // increase rank only if current score less than previous
        if (i>0 && gridData[i].VoteTotal !== gridData[i - 1].VoteTotal) {
          rank = i+1;
        } 
        gridData[i].rank = rank;
        gridData[i].isHighlighted = (rank <= getPositionsFromId(value)?"yes":"no");
      }
    }
    return(gridData);
  };

  useEffect(()=>{
    console.log("data updated: ");
    console.log(props.data);
    if(props.data) {
      rank();
      var race = getTabNameFromId(props.value);
      for (var i = 0; i < 7; i++) {
        if(props.data[i].RaceName === race){
          setData(rank(props.data[i].CandidateResults, props.value));

        }
      }
    }
  }, [props.data, props.value]);

  useEffect(()=>{
    if(data && gridApi){
      gridApi.setRowData(data);
      gridApi.sizeColumnsToFit();
    }
  }, [data, gridApi])

  const onGridReady = (params) => {
    setApi(params.api);
  };
  const getTabNameFromId = (selectedTab) => {
    return (["MAYOR", "COUNCIL", "PUBLIC", "CATHOLIC", "SENATE", "QUESTION1", "QUESTION2"])[selectedTab];
  }
  const getPositionsFromId = (selectedTab) => {
    return ([1, 8, 5, 5,3,1,1])[selectedTab];
  }
  const electionClassRules = {
    'row-leading': function(params){return params.data.isHighlighted === "yes"},
    'row': function(params){return params.data.isHighlighted !== "yes"}
  }
  return(
  <div className="ag-theme-alpine" style={{ height:'500px'}}>
  <AgGridReact onGridReady={onGridReady} rowClassRules={electionClassRules}>
    <AgGridColumn headerName = "" field="rank" maxWidth="100" type="rightAligned"></AgGridColumn>
    <AgGridColumn headerName = "Candidate Name" field="CandidateName" minWidth="200" sortable={ true }></AgGridColumn>
    <AgGridColumn key="Votes" headerName = "Votes" field="VoteTotal" minWidth="100"  sortable={ true } comparator={numberSort} sortingOrder={["desc"]} sort={"desc"}></AgGridColumn>
  </AgGridReact></div>);
}

function App() {
  
  const[data, setData] = useState(null);
  useEffect(
    () => { 
      retrieveData();
      setInterval(retrieveData, 30000);
    },
    []
  );
  const [value, setValue] = useState(0);


  const retrieveData = () => {
    axios.get('https://4tlzbtcfo7.execute-api.ca-central-1.amazonaws.com/stage/results').then(res => {
      return res.data
    }).then(res => {
        setData(res)
    }).catch(err=>{console.log(err);});
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
 }
  return (
    <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Races" variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
          <Tab label="Mayor" {...a11yProps(0)} />
          <Tab label="Councillors" {...a11yProps(1)} />
          <Tab label="Public School Board" {...a11yProps(2)} />
          <Tab label="Catholic School Board" {...a11yProps(3)} />
          <Tab label="Senate Election" {...a11yProps(4)} />
          <Tab label="Question 1 - EQUALIZATION" {...a11yProps(5)} />
          <Tab label="Question 2 - DAYLIGHT SAVINGS TIME" {...a11yProps(6)} />
        </Tabs>
      </Box>

      <ElectionGrid data={data} value={value}></ElectionGrid>
    </Box>
  );
    
}

export default App;
